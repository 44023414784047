<template>
  <div class="">
    <div class="flex items-center">
      <div class="w-1/2">
        <div class="rounded-10 h-108 bg-white flex items-center p-6 pl-3">
          <icon
            :data="icons.money"
            height="60"
            width="60"
            class="ml-4"
            original
          />
          <div class="w-full text-left ml-4">
            <div class="text-c16 text-90 font-c4">
              Chiffre d’affaire cumulé par l’agence
            </div>
            <div class="text-c24 font-c5 mt-2">
              6 266 000 <span class="text-90">FCFA</span>
            </div>
          </div>
        </div>

        <div class="rounded-10 h-79 bg-oho-green flex items-center p-6 pl-3 mt-4">
          <icon
            :data="icons.money1"
            height="60"
            width="60"
            class="ml-4"
            original
          />
          <div class="w-full text-left ml-4 text-white">
            <div class="text-c14 font-c4">
              Solde disponible en agence
            </div>
            <div class="text-c24 font-c5 mt-1">
              6 266 000 <span class="text-d">FCFA</span>
            </div>
          </div>
        </div>
      </div>


      <div class="w-1/2 ml-8 rounded-10 h-119 bg-white flex p-6">
        <div class="w-full text-left">
          <div class="flex text-c14">
            <div class="w-1/3">Liquidité</div>
            <div class="w-1/3 text-c18">
              360 000 <span class="text-90">FCFA</span>
            </div>
            <div class="w-1/3">Liquidité</div>
          </div>
          <div class="text-c24 font-c5 mt-2">
            -- <span class="text-90">---</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import money from '../../../assets/icons/money.svg'
import money1 from '../../../assets/icons/money1.svg'

export default {
  name: "Index",

  data () {
    return {
      icons: {
        arrow,
        money,
        money1
      }
    }
  },
  methods: {
    goto(){
      this.$emit('oga', true)
    }
  },
}
</script>

<style lang="scss" scoped>
  .opn {
    .porte{
      display: none;
    }
  }

  .opn:hover {
    .porte{
      display: flex;
    }
  }
</style>
