<template>
  <div class="">

    <div class="flex justify-center mt-12" v-if="charge">
      <easy-spinner
          type="dots"
          size="70"
      />
    </div>

    <div class="flex items-center">
      <div class=" w-1/2 rounded-10 h-119 bg-white flex p-6">
        <div class="w-full text-left">
          <div class="text-c24 text-60 font-c5">
            Objectif de CA mensuel
            (<span class="text-red" v-if="stats !== null">
              <span v-if="stats.goal.percent === null">0</span>
               <span v-if="stats.goal.percent !== null">{{ stats.goal.percent.toString().substr(0, 6)}}</span>
            %
          </span>)
          </div>
          <div class="text-c24 font-c5 mt-2" v-if="stats !== null">
            {{ refactorAmount(stats.goal.amount, '.') }} <span class="text-90">FCFA</span>
          </div>
        </div>
      </div>

      <div class="w-1/2 ml-8 rounded-10 h-119 bg-white flex p-6">
        <div class="w-full text-left">
          <div class="text-c24 text-60 font-c5">
            Devis en cours (<span v-if="stats !== null">{{stats.estimates.count}}</span>)
          </div>
          <div class="text-c24 font-c5 mt-2" v-if="stats !== null">
            {{  refactorAmount(stats.estimates.amount, '.')}} <span class="text-90">FCFA</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center mt-5">
      <div
        class=" w-1/2 rounded-10 h-119 bg-white flex p-6 pr-8 opn cursor-pointer"
        @click="goto"
      >
        <div class="w-1/2 text-left">
          <div class="text-c24 text-60 font-c5">
            Réalisations (<span v-if="stats !== null">{{stats.orders.count}}</span>)
          </div>
          <div class="text-c24 font-c5 mt-2" v-if="stats !== null">
            {{ refactorAmount(stats.orders.amount, '.') }} <span class="text-90">FCFA</span>
          </div>
        </div>

        <div class="w-1/2 flex items-center justify-end porte">
<!--          <div class="text-oho-blue text-c18 font-c4">-->
<!--            Portefeuille-->
<!--          </div>-->
<!--          <icon-->
<!--            :data="icons.arrow"-->
<!--            height="20"-->
<!--            width="20"-->
<!--            class="ml-4"-->
<!--            original-->
<!--          />-->
        </div>
      </div>

      <div class="w-1/2 ml-8 rounded-10 h-119 bg-white p-6">
        <div v-if="stats !== null" class="flex">
          <div class="w-1/3 text-left" v-for="(item, index) in stats.status" :key="index">
            <div class="text-c24 text-60 font-c5">
              {{ item.label }}
            </div>
            <div class="text-c24 font-c5 mt-2">
              {{ item.count }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"

export default {
  name: "Index",

  data () {
    return {
      icons: {
        arrow
      },
      charge: true,
      stats: null
    }
  },

  created () {
    this.getStats()
  },

  methods: {
    goto(){
      // this.$emit('oga', true)
    },

    refactorAmount (a, b) {
      a = '' + a;
      b = b || ' ';
      var c = '',
          d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (var i = a.length-1; i >= 0; i--) {
        c = (d != 0 && d % 3 == 0) ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    },

    getStats () {
      http.get(apiroutes.baseURL + apiroutes.agenceStat)
          .then(response => {
            this.stats = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            if (error.data.statusCode === 401) {
              this.$router.go('/')
            }
          })
    }
  },
}
</script>

<style lang="scss" scoped>
  .opn {
    .porte{
      display: none;
    }
  }

  .opn:hover {
    .porte{
      display: flex;
    }
  }
</style>
