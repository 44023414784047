<template>
  <div class="">
    <div class="flex items-center">
      <div class="w-2/3 flex text-left text-c48 items-center font-c6">
        <icon
          v-if="icon !== null"
          :data="icons.back"
          height="70"
          width="70"
          class="mr-2"
          @click="$emit('oga', false)"
          original
        />
        <div class="ml-4">
          Portefeuille
        </div>
      </div>

<!--      <div class="w-1/3">-->
<!--        <div class="flex justify-end">-->
<!--          <div class="w-2/5">-->
<!--            <bouton-->
<!--              :icon="icons.add"-->
<!--              label="Creer"-->
<!--              radius="5.4px"-->
<!--              weight="600"-->
<!--              @oga="retourClick"-->
<!--            />-->
<!--            <div class="absolute">-->
<!--              <select-filtre-->
<!--                v-if="activeDetail"-->
<!--                :option="optionAdd"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div class="mt-10">
      <globalInfo />
    </div>
  </div>
</template>

<script>
// import bouton from '../../helper/add/button'
import add from '../../../assets/icons/add.svg'
import back from '../../../assets/icons/backBut.svg'
import globalInfo from '../portefeuille/globalInfo'

export default {
  name: "Index",

  components: {
    // bouton,
    // selectFiltre,
    globalInfo
  },

  data () {
    return {
      activeDetail: false,
      optionAdd: ['Devis', 'Rendez-vous', 'Plainte', 'Rendez-vous', 'Appel', 'Note'],
      icons: {
        add,
        back
      }
    }
  },
  mounted() {
  },
  methods: {
    retourClick(){
      this.activeDetail =! this.activeDetail
    }
  }
}
</script>

<style scoped>

</style>
