<template>
  <div class="p-10 pr-16 all">
    <div v-if="!activePortefeuille">
      <div class="flex items-center">
        <div
          class="w-2/3 text-left text-c40 font-c6"
          @click="activeDetail = false"
        >
          Tableau de bord
        </div>
<!--        <div class="w-1/3">-->
<!--          <div class="flex justify-end">-->
<!--            <div class="w-2/5">-->
<!--              <bouton-->
<!--                :icon="icons.add"-->
<!--                label="Creer"-->
<!--                radius="5.4px"-->
<!--                weight="600"-->
<!--                @oga="retourClick"-->
<!--              />-->
<!--              <div class="absolute">-->
<!--                <select-filtre-->
<!--                  v-if="activeDetail"-->
<!--                  :option="optionAdd"-->
<!--                  size="16px"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>

      <div
        class="mt-12 w-full"
        @click="activeDetail = false"
      >
        <globalStat @oga="activePortefeuille = true" />
      </div>

<!--      <div-->
<!--        class="mt-12 w-full"-->
<!--        @click="activeDetail = false"-->
<!--      >-->
<!--        <turnover />-->
<!--      </div>-->

<!--      <div-->
<!--        class="mt-12 w-full"-->
<!--        @click="activeDetail = false"-->
<!--      >-->
<!--        <sell-stats />-->
<!--      </div>-->
    </div>

    <portefeuille v-if="activePortefeuille" @oga="activePortefeuille = false"/>
  </div>
</template>

<script>
// import bouton from '../../component/helper/add/button'
import add from '../../assets/icons/add.svg'
import globalStat from '../../component/cdevs/dashboard/globalStat'
// import turnover from '../../component/cdevs/dashboard/turnover'
// import sellStats from '../../component/cdevs/dashboard/sellStat'
// import selectFiltre from '../../component/helper/form/selectFiltre'
import portefeuille from '../../component/cdevs/dashboard/portefeuille'

export default {
  name: "Index",

  components: {
    // bouton,
    globalStat,
    // turnover,
    // sellStats,
    // selectFiltre,
    portefeuille
  },

  data () {
    return {
      activePortefeuille: false,
      activeDetail: false,
      optionAdd: ['Devis', 'Rendez-vous', 'Plainte', 'Rendez-vous', 'Appel', 'Note'],
      icons: {
        add
      }
    }
  },
  mounted() {
  },
  methods: {
    retourClick(){
      this.activeDetail =! this.activeDetail
    }
  }
}
</script>

<style scoped>
.all{
  min-height: 100vh;
}
</style>
